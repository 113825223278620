import React from 'react';
import Layout from '@components/layout/layout';
import { graphql, Link } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import Seo from '@components/seo/seo';
import ArrowSvg from '@img/um-arrow-pagination.svg';
import Post from '@components/post/post';
import SectionTag from '@components/section-tag/section-tag';
import BgGuides from '@img/bg-guides.svg';
import CategoryButtons from '@domain/category-buttons/category-buttons';
import config from '../../config.json';
import { renderToString } from 'react-dom/server';

const BlogList: React.FC = ({ data, pageContext }: any) => {
  const { categories } = pageContext;
  const activeCategory = categories.length > 1 ? 'wszystko' : categories[0];
  const wpPage = data.wpPage;

  let postsCount = config.POSTS_PER_PAGE;
  let posts = data.allWpPost.edges;
  let storagePosts = posts.slice(postsCount);
  
  if(typeof window !== 'undefined'){
    sessionStorage.setItem('posts', JSON.stringify(storagePosts));
  }
  if(typeof document !== `undefined`) {
    document.addEventListener('scroll', function(e) {
      if(Boolean(document.getElementsByClassName('infinite-scroll').length) && typeof window !== 'undefined') {
        let scrollPosition = window.scrollY;
        var lastPost = document.querySelector('.post-box:last-child');
        
        if(scrollPosition > lastPost.offsetTop) {
          let postsList = JSON.parse(sessionStorage.getItem('posts'));
          let postsToShow = postsList.slice(0, postsCount);
          
          sessionStorage.setItem('posts', JSON.stringify(postsList.slice(postsCount)));

          let html = '';

          postsToShow.forEach(post => {
            let image = getImage(post.node.featuredImage.node.localFile)!;

            html += `<div class="post-box">`+renderToString(<Post post={post.node} image={image} key={post.node.id} forcedCategoryName={activeCategory === 'wszystko' ? undefined : activeCategory }/>) +`</div>`;
          });
          
          document.querySelector('.infinite-scroll').innerHTML += html;
        }
      }
    });
  }

  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.focuskw}
        pathname="/blog/"
      />
      <div className="bg-blueLight">
        <div
          className="w-full !bg-[length:0px]  lg:!bg-[length:500px]"
          style={{
            background: `url(${BgGuides})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '110%  -160px',
          }}
        >
          <div className="container mx-auto px-4 py-6 lg:py-20">
            <div className="">
              <SectionTag tag="span">STREFA WIEDZY</SectionTag>
              <h1 className="mt-6 text-left text-[32px] font-black leading-snug text-text lg:text-left lg:text-[42px] lg:leading-tight">
                Poradniki ubezpieczeniowe
              </h1>
              <p className="mt-6 max-w-xl text-sm lg:text-lg">
                Skorzystaj z wiedzy naszych specjalistów. Dowiedz się więcej
                o&nbsp;ubezpieczeniach dla dzieci i wybieraj świadomie.
              </p>
            </div>
            <CategoryButtons activeCategory={activeCategory} />
            <div className="mt-10 grid auto-rows-max grid-cols-1 gap-4 lg:gap-7 xl:grid-cols-3 infinite-scroll">
              {posts.slice(0, postsCount).map((post: any) => {
                const image = getImage(post.node.featuredImage.node.localFile)!;
                return (
                  <div className="post-box">
                    <Post post={post.node} image={image} forcedCategoryName={activeCategory === 'wszystko' ? undefined : activeCategory } />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default BlogList;

export const pageQuery = graphql`
  query BlogPostsList($categories: [String!]) {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { slug: { in: $categories } } } }
      }
      sort: { order: DESC, fields: dateGmt }
    ) {
      edges {
        node {
          dateGmt(formatString: "DD MMMM YYYY", locale: "pl")
          id
          title
          excerpt
          slug
          featuredImage {
            node {
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 750, height: 400, quality: 90)
                }
              }
            }
          }
          modifiedGmt(formatString: "DD.MM.YYYY")
          content
          zajawkaBlog {
            zajawka
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "poradniki" }) {
      seo {
        metaDesc
        title
        focuskw
      }
    }
  }
`;
